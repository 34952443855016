import React from "react";

function FooterDev() {
  return (
    <footer className="w-full bg-secondary_black text-gray-50 text-center flex flex-col items-center justify-center">
      {/* <div className="pt-4 text-sm">
        <ul className="flex justify-center">
          <li className="hover:text-red transition-all px-6 pl-0">
            <a href="/tnc.html" target="_blank" rel="noreferrer">
              Terms & Condition
            </a>
          </li>
          <li className="hover:text-red transition-all px-6">
            <a href="/privacyPolicy.html" target="_blank" rel="noreferrer">
              Privacy Policy
            </a>
          </li>
          <li className="hover:text-red transition-all px-6">
            <a href="/refundPolicy.html" target="_blank" rel="noreferrer">
              Refund Policy
            </a>
          </li>
        </ul>
      </div> */}

      <p className="p-4">
        <span>Made by </span>
        <span className="font-black-hawk tracking-wider text-lg ml-1">
          <span className="text-red text-xl">CU</span>
          <span>r</span>
          <span className="text-red text-xl">B</span>
          <span>rain</span>
        </span>
      </p>
    </footer>
  );
}

export default FooterDev;

import React, { Children } from "react";
import { Close } from "@mui/icons-material";
import { ReactComponent as Fb } from "assets/icons/fbicon.svg";
import { ReactComponent as Insta } from "assets/icons/instaicon.svg";
import { ReactComponent as Linkedin } from "assets/icons/linkedinicon.svg";
import { ReactComponent as Github } from "assets/icons/github.svg";

const ClubInfo = ({ closeModal, title, members }) => {
  return (
    <div className="popup">
      <div className="popup-content rounded-lg pt-4">
        <div className="flex p-2">
          <h3 className="grow text-center font-bold text-3xl tracking-wider font-black-hawk">
            {title}
          </h3>
          <Close
            className="club-more-info-dialog-close-btn cursor-pointer hover:scale-110"
            onClick={closeModal}
          />
        </div>

        <div className="flex flex-col pl-12 pr-16 pt-4 pb-2 overflow-y-auto">
          {Children.toArray(members.map((item) => <Member data={item} />))}
        </div>
      </div>
    </div>
  );
};

export default ClubInfo;

const Member = ({ data }) => {
  const links = data.links;

  return (
    <div className="flex justify-between py-2">
      <img
        src={data.image}
        alt={data.name}
        className="w-32 h-32 rounded-full object-cover object-center"
      />
      <div className="flex flex-col justify-center grow pl-4">
        <p className="font-semibold">{data.name}</p>
        <small>{data.title}</small>

        {links && (
          <div className="flex -ml-2 pt-2">
            {links.fb && (
              <a
                href={links.fb}
                target="_blank"
                rel="noreferrer"
                className="mx-2"
              >
                {" "}
                <Fb className="h-8 w-8 hover:-translate-y-1 transition-all" />{" "}
              </a>
            )}
            {links.insta && (
              <a
                href={links.insta}
                target="_blank"
                rel="noreferrer"
                className="mx-2"
              >
                {" "}
                <Insta className="h-8 w-8 hover:-translate-y-1 transition-all" />{" "}
              </a>
            )}
            {links.linkedin && (
              <a
                href={links.linkedin}
                target="_blank"
                rel="noreferrer"
                className="mx-2"
              >
                {" "}
                <Linkedin className="h-8 w-8 hover:-translate-y-1 transition-all" />{" "}
              </a>
            )}
            {links.github && (
              <a
                href={links.github}
                target="_blank"
                rel="noreferrer"
                className="mx-2"
              >
                {" "}
                <Github className="h-8 w-8 hover:-translate-y-1 transition-all" />{" "}
              </a>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

import React from "react";
import { ReactComponent as Fb } from "assets/icons/fbicon.svg";
import { ReactComponent as Insta } from "assets/icons/instaicon.svg";
import { ReactComponent as Linkedin } from "assets/icons/linkedinicon.svg";
import { ReactComponent as Github } from "assets/icons/github.svg";

function TeamGridItem({ id, image, name, desig, links }) {
  // desig- designation
  // links- object of links(fb,insta,linkedin,github)

  return (
    <div
      className="team-card col-span-1 bg-cover"
      style={{ backgroundImage: `url(${image})`, backgroundPosition: "center" }}
    >
      <div className="team-card-det">
        <p className="text-lg">{name}</p>
        <p className="text-sm">{desig}</p>
      </div>
      <div className="links">
        {links.fb && (
          <a href={links.fb} target="_blank" rel="noreferrer">
            {" "}
            <Fb className="h-8 w-8 hover:-translate-y-1 transition-all" />{" "}
          </a>
        )}
        {links.insta && (
          <a href={links.insta} target="_blank" rel="noreferrer">
            {" "}
            <Insta className="h-8 w-8 hover:-translate-y-1 transition-all" />{" "}
          </a>
        )}
        {links.linkedin && (
          <a href={links.linkedin} target="_blank" rel="noreferrer">
            {" "}
            <Linkedin className="h-8 w-8 hover:-translate-y-1 transition-all" />{" "}
          </a>
        )}
        {links.github && (
          <a href={links.github} target="_blank" rel="noreferrer">
            {" "}
            <Github className="h-8 w-8 hover:-translate-y-1 transition-all" />{" "}
          </a>
        )}
      </div>
    </div>
  );
}

export default TeamGridItem;

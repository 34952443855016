import React from "react";
import "styles/home.css";
import Spline3D from "components/home/Spline3D";
import { useMediaQuery } from "@mui/material";
import Nav from "components/nav/Navbar";
import HomeBasic from "components/home/HomeBasic";
import HomePc from "components/home/HomePc";
import Tnc from "components/tnc/Tnc";

export default function Home() {
  const isMobile = useMediaQuery("(max-width:500px)");

  return (
    <div className="h-screen w-screen overflow-hidden">
      <div className="relative w-full h-full">
        <div className="absolute top-4 sm:top-6 right-4 sm:right-8">
          <button
            className="lg:text-xl text-gray-50 bg-red/50 rounded-md border-2 mr-4 sm:mr-8 border-red px-4 py-1 drop-shadow-[0_0_0.3rem_red] hover:bg-transparent transition-all"
            onClick={openRegisterForm}
          >
            Register Now!
          </button>
        </div>

        {/* <div className="absolute bottom-2 right-2 pb-4">
          <Tnc />
        </div> */}
        {isMobile ? (
          <>
            <Nav />
            <HomeBasic />
          </>
        ) : (
          <>
            <Nav />
            {/* <HomePc /> */}
            <Spline3D />
          </>
        )}
      </div>
    </div>
  );
}

const openRegisterForm = () => {
  window.open(
    "https://docs.google.com/forms/d/e/1FAIpQLScmqmsFbv0o5bC6qSwjBRGfQ4WEobezCQ9ZrJ7UHw7kQtGHTQ/viewform?usp=sf_link",
    "_blank"
  );
};

import Cookies from "universal-cookie";

const cookies = new Cookies();

export const setToken = (token) => {
  const current_timestamp = Date.now();
  const expiryTime = current_timestamp + 60 * 60 * 23; // (60 * 60 * 23) 23 hours
  cookies.set("expiry", expiryTime, { path: "/" });

  cookies.set("token", token, { path: "/" });
};

export const getToken = () => {
  const expiry = cookies.get("expiry");
  if (!expiry) {
    // expiry-cookie not found
    logout();
    return null;
  }

  const current_timestamp = Date.now();
  if (current_timestamp > Number(expiry)) {
    // token expired
    logout();
    return null;
  }

  return cookies.get("token");
};

export const logout = () => {
  cookies.remove("expiry", { path: "/" });
  cookies.remove("token", { path: "/" });
};

const env = process.env.NODE_ENV;

let server_api = null,
  sso_redirect = null,
  client_url = null,
  razorpay_key = null,
  admin_url = null;

if (env === "development") {
  server_api = process.env.REACT_APP_DEV_SERVER_API;
  sso_redirect = process.env.REACT_APP_DEV_SSO_REDIRECT;
  client_url = process.env.REACT_APP_DEV_CLIENT_URL;
  razorpay_key = process.env.REACT_APP_DEV_RAZORPAY_KEY;
  admin_url = process.env.REACT_APP_DEV_ADMIN_URL;
} else if (env === "production") {
  server_api = process.env.REACT_APP_PROD_SERVER_API;
  sso_redirect = process.env.REACT_APP_PROD_SSO_REDIRECT;
  client_url = process.env.REACT_APP_PROD_CLIENT_URL;
  razorpay_key = process.env.REACT_APP_PROD_RAZORPAY_KEY;
  admin_url = process.env.REACT_APP_PROD_ADMIN_URL;
}

client_url = client_url + "get_token?redirect=" + sso_redirect;

export { server_api, client_url, razorpay_key, admin_url };

import React from "react";

function ClubCardBg() {
  return (
    <div class="lines">
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
    </div>
  );
}

export default ClubCardBg;

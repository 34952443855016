import React from "react";

function Teamgrid({ children }) {
  return (
    <div className="grid grid-cols-3 grid-flow-row gap-20 place-items-center team-container mx-[10%] px-4">
      {children}
    </div>
  );
}

export default Teamgrid;

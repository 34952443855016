import React from "react";

const HomeBasic = () => {
  return (
    <div className="w-full">
      <img
        // src={isMobile ? "/images/home-sm-2.jpg" : "/images/home.jpg"}
        // src="/images/EDGE24-01.png"
        src="/images/home-sm-2.png"
        alt=""
        className="w-full min-h-[calc(100vh-60px)] object-center"
      />
    </div>
  );
};

export default HomeBasic;

import { Modal } from "@mui/material";
import React from "react";
import { useState } from "react";
import ClubInfo from "./ClubInfo";
import ClubCardBg from "./ClubCardBg";

function ClubCard({ image, name, data, members, position }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="club-card">
        <div className="face face1 rounded-lg">
          <ClubCardBg />
          <div className="name-logo">
            <img src={image} alt={name} className="club-logo" />
            <h3 className="club-name text-white pl-2">{name}</h3>
          </div>
        </div>
        <div className="face face2 rounded-lg">
          <div className="content">
            <p>{data}</p>
            {/* <br />
            <center>
              <button
                type="button"
                onClick={() => setOpen(true)}
                className="more-info-btn text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
              >
                More info
              </button>
            </center> */}
          </div>
        </div>
      </div>

      <Modal open={open}>
        <>
          <ClubInfo
            title={name}
            members={members}
            closeModal={() => setOpen(false)}
          />
        </>
      </Modal>
    </>
  );
}

export default ClubCard;

import React from "react";
import "styles/team.css";
import coreTeam from "data/CoreTeam.json";
import devTeam from "data/DevTeam.json";
import designTeam from "data/DesignTeam.json";
import Nav from "components/nav/Navbar";
import Teamgrid from "./Teamgrid";
import EventDetailsBg from "components/events/EventDetailsBg";
import GridItem from "./TeamGridItem";
import FooterDev from "components/footer/FooterDev";

export default function Team() {
  return (
    <div className="relative">
      <EventDetailsBg />
      <Nav />

      <div className="mb-12 px-8 py-4">
        <div className="mt-16">
          <div className="w-full p-1">
            <div className="team-heading relative text-center p-6 text-4xl text-red shadow-sm pb-8 mb-8">
              <h1 className="absolute top-1/2 left-1/2 font-black-hawk text-2xl sm:text-3xl md:text-5xl">
                C<span className="text-gray-50">OR</span>E TE
                <span className="text-gray-50">AM</span>{" "}
              </h1>
            </div>
          </div>
          <Teamgrid>
            {coreTeam.map((e) => {
              return <GridItem key={e.id} {...e} />;
            })}
          </Teamgrid>
        </div>
        <div className="mt-16">
          <div className="w-full p-1">
            <div className="team-heading relative text-center p-6 text-4xl text-red shadow-sm pb-8 mb-8">
              <h1 className="absolute top-1/2 left-1/2 font-black-hawk text-2xl sm:text-3xl md:text-5xl">
                DEV<span className="text-gray-50">ELOP</span>MENT TE
                <span className="text-gray-50">AM</span>{" "}
              </h1>
            </div>
          </div>
          <Teamgrid>
            {devTeam.map((e) => {
              return <GridItem key={e.id} {...e} />;
            })}
          </Teamgrid>
        </div>
        <div className="mt-16">
          <div className="w-full p-1">
            <div className="team-heading relative text-center p-6 text-4xl text-red shadow-sm pb-8 mb-8">
              <h1 className="absolute top-1/2 left-1/2 font-black-hawk text-2xl sm:text-3xl md:text-5xl">
                DE<span className="text-gray-50">SIG</span>N TE
                <span className="text-gray-50">AM</span>{" "}
              </h1>
            </div>
          </div>
          <Teamgrid>
            {designTeam.map((e) => {
              return <GridItem key={e.id} {...e} />;
            })}
          </Teamgrid>
        </div>
      </div>

      <FooterDev />
    </div>
  );
}

import React, { useEffect, useState } from "react";
import styles from "styles/sponsors.module.css";
import Nav from "components/nav/Navbar";
import SponsorCard from "components/sponsors/SponsorCard";
// import axios from "axios";
// import { server_api } from "config/config";
// import Loading from "components/loadings/Loading";
import FooterDev from "components/footer/FooterDev";
import Comingsoon from "components/events/comingsoon/Comingsoon";
import sponsors from "../data/Sponsors.json";

export default function SponsorPage() {
  const [data, setData] = useState([]);
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    // async function getData(cancelToken) {
    //   const res = await axios({
    //     baseURL: server_api,
    //     url: "sponsors",
    //     cancelToken: cancelToken,
    //   });

    //   // console.log(res);
    //   if (res?.data) {
    //     setData(res.data.sponsors.sort((a, b) => a.order - b.order));
    //   }

    //   setLoading(false);
    // }

    // const source = axios.CancelToken.source();
    // getData(source.token);

    // return () => {
    //   if (source) source.cancel();
    // };
    setData(sponsors.sort((a, b) => a.order - b.order));
  }, []);

  return (
    <div className={styles.root}>
      <Nav />

      {/* {loading && <Loading />} */}

      <div className={styles.main}>
        <div className={styles.grid}>
          {data?.map((item, _idx) => (
            <SponsorCard key={_idx.toString()} data={item} />
          ))}
        </div>

        {!data?.length ? <Comingsoon /> : null}
      </div>

      <FooterDev />
    </div>
  );
}

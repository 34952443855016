import React, { useState } from "react";
import Spline from "@splinetool/react-spline";
import Loading from "components/loadings/HomeLoader";

const spline_link =
  "https://prod.spline.design/d7w8yNlEimUrFGq4/scene.splinecode";
// "https://prod.spline.design/phYXnVczKarEVsYU/scene.splinecode"
// "https://prod.spline.design/XMQe7HIdKiNOHw4B/scene.splinecode";

const Spline3D = () => {
  const [loading, setLoading] = useState(true);

  return (
    <>
      {loading && <Loading />}

      {/* <div className="h-full"> */}
      <div
        className="h-full flex flex-row justify-center items-center"
        style={{
          backgroundImage: "url(/bg-1.jpg)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <Spline
          onLoad={() => setTimeout(() => setLoading(false), 200)}
          scene={spline_link}
        />
      </div>
    </>
  );
};

export default Spline3D;

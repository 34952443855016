import React from "react";

function GalleryItem({ image, eventName, areaName, openLightbox }) {
  return (
    <div
      className="gallery-container overflow-hidden m-1 cursor-pointer"
      style={{ gridArea: areaName }}
      onClick={() => openLightbox({ url: image, caption: eventName })}
    >
      <figure className="gallery-thumb relative h-full">
        <img src={image} alt="" className="gallery-img block w-full h-full" />
        <figcaption className="gallery-caption opacity-0 absolute bottom-0 left-0 w-full pt-[25px] pl-[15px] pr-[15px] pb-[10px] text-lg text-gray-50 ">
          {eventName}
        </figcaption>
      </figure>
    </div>
  );
}

export default GalleryItem;

import React from "react";
import styles from "styles/burger_icon.module.css";

const Burger = ({ open, setOpen }) => {
  return (
    <div onClick={() => setOpen(!open)} className={styles.Burger}>
      <div
        style={{
          transform: `${open ? "rotate(45deg)" : "rotate(0)"}`,
        }}
      />
      <div
        style={{
          opacity: `${open ? "0" : "1"}`,
          transform: `${open ? "translateX(20px)" : "translateX(0)"}`,
          color: "red",
        }}
      />
      <div
        style={{
          transform: `${open ? "rotate(-45deg)" : "rotate(0)"}`,
        }}
      />
    </div>
  );
};

export default Burger;

import EventDetailsBg from "components/events/EventDetailsBg";
import Nav from "components/nav/Navbar";
import React, { useState } from "react";
import "styles/clubs.css";
import ClubCard from "./ClubCard";
import ClubData from "data/ClubsCard.json";
import Lottie from "react-lottie";
import animation from "lotties/about";
import Footer from "components/footer/Footer";
import FooterDev from "components/footer/FooterDev";

export default function Clubs() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="relative">
      <EventDetailsBg />
      <Nav />
      <div className="mt-16 px-6">
        <div className="text-3xl flex flex-col md:flex-row items-center justify-evenly ">
          <div className="flex items-center justify-center text-center">
            <h1 className="font-black-hawk text-center text-[3rem] sm:text-[6rem] lg:text-[9rem] text-gray-50">
              Ab<span className="text-red">out U</span>s
            </h1>
          </div>
          <div className="flex items-center justify-center h-[250px] w-[250px] sm:h-[300px] sm:w-[300px] lg:h-[400px] lg:w-[400px] pointer-events-none">
            <Lottie options={defaultOptions} />
          </div>
        </div>
        <div className="flex justify-center">
          <Description />
        </div>
      </div>

      <div className="w-full h-full py-12">
        <div className="w-full">
          <div className="flex justify-center w-full text-gray-50 pb-16 text-[4rem] font-black-hawk ">
            Ou<span className="text-red">r Clu</span>bs
          </div>
          <div className="club-section">
            {ClubData.map((item, idx, arr) => {
              return (
                <ClubCard key={item.id} {...item} position={idx - arr.length} />
              );
            })}
          </div>
        </div>
      </div>

      <Footer />
      <FooterDev />
    </div>
  );
}

const Description = () => {
  const [showmore, setShowmore] = useState(false);

  return (
    <p className="px-1 py-8 text-md sm:text-base md:px-16 lg:px-24 text-gray-50 text-justify">
      The official Science and Technology club of Techno Main Salt Lake -
      Geekonix marked its inception in the year 2005 and since then, the journey
      to inspire young minds to harbour and nurture their technological
      wavelengths in a friendly environment has created and established
      milestones in the field. Our enthusiasts have paved their way towards
      innovation, creation and ideation of technology and through cultivating
      the skills they have created a friendly atmosphere of competitions in
      different fields of
      {!showmore && (
        <span
          className="text-gray-400 cursor-pointer"
          onClick={() => setShowmore(true)}
        >
          ...show more
        </span>
      )}
      {showmore && (
        <>
          {" "}
          interest. Under the hood of Geekonix we have 10 sub-clubs covering
          various domains of gaming, coding, robotics, entrepreneurship,
          photography, debating, etc. Geekonix organizes EDGE, the annual
          techno-management fest of our college where we provide the exuberance
          of 3-days comprising of 30+ technical events. Amidst the plethora of
          events we also have fun-filled and light-hearted evenings like EDGE
          NIGHTS. During EDGE, we witness participation from all over the
          country all of them coming together to the final showdown and perform
          in their fiercest way to beat the better and be the best.
          <span
            className="text-gray-400 cursor-pointer"
            onClick={() => setShowmore(false)}
          >
            {" ...show less"}
          </span>
        </>
      )}
    </p>
  );
};

import { createContext, useReducer, useMemo } from "react";
import { addToCart, removeFromCart } from "utils/cart";

const CART = createContext();

export default CART;

function reducer(state, action) {
  // console.log({ state, action });

  switch (action.type) {
    case "ADD":
      return addToCart(state, action.payload);

    case "REMOVE":
      return removeFromCart(state, action.payload);

    case "CLEAR":
      return [];

    default:
      return state;
  }
}

// {
//   name: "demo",
//   price: "100",
//   id: "id" //event-code
// }

export function CartContext(props) {
  const [cart, dispatch] = useReducer(reducer, []);
  const value = useMemo(() => {
    return {
      cart,
      dispatch,
    };
  }, [cart]);

  return <CART.Provider value={value}>{props.children}</CART.Provider>;
}

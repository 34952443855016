import React, { useEffect } from "react";
import { init } from "utils/event-details-bg";

function EventDetailsBg() {
  useEffect(() => {
    init();
  }, []);

  return (
    <div
      className="event_details_bg m-0 p-0 z-[-1] h-full w-full fixed top-0 left-0 "
      id="bg"
    >
      <canvas id="stars" width="300" height="300"></canvas>
    </div>
  );
}

export default EventDetailsBg;

import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";

import { CartContext } from "components/context/CartContext";
// import { TemporaryTokenProvider } from "components/context/TemporaryToken";
import { UserContext } from "components/context/UserContext";

import Home from "./pages/Home.js";
import Events from "./pages/Events.js";
import NotFound from "components/error/NotFound";
import Gallery from "components/gallery/Gallery";
import Team from "components/team/Team";
import SponsorPage from "pages/Sponsors";
import Clubs from "components/clubs/Clubs";
import Game from "game";
// import Profile from "pages/Profile";
// import Psuccess from "components/payment/Psuccess";
// import Pfailure from "components/payment/Pfailure";
// import Emails from "components/emails/Emails";
import ScrollToTop from "utils/ScrollOnTop";
import { AdminLogin } from "components/admin/AdminLogin";
import GeneralRules from "pages/GeneralRules";

function App() {
  return (
    <BrowserRouter>
      {/* <TemporaryTokenProvider> */}
      <UserContext>
        <CartContext>
          <ScrollToTop />
          <Routes>
            <Route exact path="/" element={<Home />}></Route>

            {/* <Route exact path="/events" element={<Events />}></Route> */}
            <Route exact path="/gallery" element={<Gallery />}></Route>
            <Route exact path="/team" element={<Team />}></Route>
            <Route exact path="/sponsors" element={<SponsorPage />}></Route>
            <Route exact path="/about-us" element={<Clubs />}></Route>
            {/* <Route exact path="/rules" element={<GeneralRules />}></Route> */}
            {/* <Route exact path="/edge-games" element={<Game />}></Route> */}

            {/* <Route exact path="/profile" element={<Profile />}></Route> */}
            {/* <Route exact path="/paymentsuccess" element={<Psuccess />}></Route> */}
            {/* <Route exact path="/paymentfailure" element={<Pfailure />}></Route> */}
            {/* <Route exact path="/invoice" element={<Emails />} /> */}

            {/* <Route exact path="/admin-login" element={<AdminLogin />}></Route> */}

            <Route path="*" element={<NotFound />}></Route>
          </Routes>
        </CartContext>
      </UserContext>
      {/* </TemporaryTokenProvider> */}
    </BrowserRouter>
  );
}

export default App;
import { createContext, useReducer, useMemo, useEffect } from "react";
import axios from "axios";
import { server_api } from "config/config";
import { getToken } from "utils/auth";

const USER = createContext();
export default USER;

function reducer(state, action) {
  switch (action.type) {
    case "SET":
      return action.payload;

    case "UPDATE_USER_DATA":
      return { isLoggedIn: true, token: state.token, data: action.payload };

    case "UPDATE_REGISTERED_EVENTS":
      const obj = { ...state };
      if (obj.data) {
        obj.data.registeredEvents = action.payload;
      }
      return obj;

    case "CLEAR":
      return { isLoggedIn: false, token: "", data: null };

    default:
      return state;
  }
}

export function UserContext(props) {
  const token = getToken();
  const initalValue = { isLoggedIn: false, token: "", data: null };
  if (token) {
    initalValue.isLoggedIn = true;
    initalValue.token = token;
  }

  const [user, setUser] = useReducer(reducer, initalValue);
  // console.log({ user });

  const value = useMemo(() => {
    return {
      user,
      setUser,
    };
  }, [user]);

  useEffect(() => {
    async function getUserData(user_token) {
      const res = await axios({
        method: "GET",
        headers: {
          Authorization: user_token,
        },
        baseURL: server_api,
        url: "users/myProfile",
      }).catch((err) => {
        // console.log(err);
      });
      // console.log("User => ", res);

      if (res?.data?.success) {
        setUser({ type: "UPDATE_USER_DATA", payload: res.data.user });
      }
    }
    let source = null;
    if (token) {
      source = axios.CancelToken.source();
      getUserData(token);
    }

    return () => {
      if (source) source.cancel();
    };
  }, [token]);

  return <USER.Provider value={value}>{props.children}</USER.Provider>;
}

export const addToCart = (cart, item) => {
  const new_cart = [...cart] || [];
  new_cart.push(item);

  return new_cart;
};

export const removeFromCart = (cart, item) => {
  const new_cart = cart.filter((el) => el.id !== item);

  return new_cart;
};

export const searchCart = (cart, searchitem) => {
  if (!Array.isArray(cart)) return false;

  const r = cart.filter((item) => item.id === searchitem);
  if (r?.length > 0) return true;
  else return false;
};
